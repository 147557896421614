:root {
    --main-bg-color: #4CAF50;
    --input-hover-color: #13AF50;
    --input-disabled-color: gray;
}

body {
    background-color: var(--main-bg-color);
    color: white;
}

.navbar {
    background-color: var(--main-bg-color);
    color: white
}

.logo {
    height: 25px;
    margin: 0 10px
}

.modal-content {
    color: black;
}

div.loading .viewedit {
    display: none
}

div.edit .loading,
div.edit .view {
    display: none
}

div.view .loading,
div.view .edit {
    display: none
}

div.view input,
div.view select {
    pointer-events: none;
    background-color: #e9ecef
}

table.has-totals tbody tr:last-child {font-weight: bold}
table.has-totals tbody td:last-child {font-weight: bold}

label.child-deleted {text-decoration: line-through}

.signup-list div.row:nth-child(even), .member-list div.row:nth-child(even) {
    background-color: #eeeeee;
    padding: 3px 0;
}

div.form-check {
    margin-left: 1em
}

ul.radio-container  {
    list-style: none;
    margin: 0;
    padding: 0;
}

span.link:hover {
    cursor: pointer;
}

.btn {
    margin-right: 10px;
}

.fancy-box {
    min-width: 300px;
    border-radius: 4px;
    border-style: solid;
    border-radius: 20px;
    box-shadow: 4px 4px black;
    background-color: white;
    color: black;
    padding: 30px;
}

.center {
    margin: 0 auto;
}

.center-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bottom-text {
    margin-top: 50px;
}

.navbar {
    padding-left: 1em;
    padding-right: 1em;
}

.navbar-nav li a {
    color: white;
}

.navbar-nav li a:hover {
    color: black;
}

.green-button {
    background-color: var(--main-bg-color);
    border-color: var(--main-bg-color);
    color: white;
}

.green-button:hover {
    background-color: var(--input-hover-color);
    border-color: var(--input-hover-color);
    color: white;
}

.green-button:disabled {
    background-color: var(--input-disabled-color);
    border-color: var(--input-disabled-color);
    color: white;
}

.form-check-input:checked {
    background-color: var(--main-bg-color);
    border-color: var(--main-bg-color);
}

.form-check-input:checked:hover {
    background-color: var(--input-hover-color);
    border-color: var(--input-hover-color);
}

.description {
    font-size: 80%;
}

.child-container {
    display: flex;
}

.child-name {
    flex: 3;
}

.child-grade {
    flex: .5;
}

#snackbar {
    /* Hidden by default. Visible on click */
    visibility: hidden;
    /* Set a default minimum width */
    min-width: 150px;
    /* Divide value of min-width by 2 */
    margin-left: -75px;
    /* Black background color */
    background-color: #333;
    /* White text color */
    color: #fff;
    /* Centered text */
    text-align: center;
    /* Rounded borders */
    border-radius: 10px;
    /* Padding */
    padding: 16px;
    /* Sit on top of the screen */
    position: fixed;
    /* Add a z-index if needed */
    z-index: 1;
    /* Center the snackbar */
    left: 50%;
    /* 30px from the bottom */
    bottom: 30px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
    /* Show the snackbar */
    visibility: visible;
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}